import React, { useState, useEffect, useCallback } from 'react';
import styled, { keyframes } from 'styled-components';

interface ScrollGameProps {
  onWin: () => void;
}

const glow = keyframes`
  0%, 100% { text-shadow: 0 0 5px rgba(255, 215, 0, 0.5); }
  50% { text-shadow: 0 0 20px rgba(255, 215, 0, 0.8); }
`;

const HiddenWord = styled.span<{ isActive: boolean }>`
  cursor: default;
  color: ${props => props.isActive ? '#FFD700' : 'inherit'};
  animation: ${props => props.isActive ? glow : 'none'} 2s infinite;
  transition: color 0.3s ease;
`;

const MAGIC_WORDS = ['moon', 'lambo', 'hodl', 'degen', 'wen'];

interface ScrollGameHelpers {
  wrapWord: (word: string) => JSX.Element;
}

const ScrollGame: React.FC<ScrollGameProps> = ({ onWin }) => {
  const [foundWords, setFoundWords] = useState<string[]>([]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  
  useEffect(() => {
    // Reset game state every hour
    const interval = setInterval(() => {
      setFoundWords([]);
      setCurrentWordIndex(0);
    }, 3600000);
    
    return () => clearInterval(interval);
  }, []);
  
  const handleWordClick = useCallback((word: string) => {
    if (word === MAGIC_WORDS[currentWordIndex] && !foundWords.includes(word)) {
      const newFoundWords = [...foundWords, word];
      setFoundWords(newFoundWords);
      setCurrentWordIndex(prev => prev + 1);
      
      if (newFoundWords.length === MAGIC_WORDS.length) {
        onWin();
      }
    } else {
      // Reset progress if wrong word is clicked
      setFoundWords([]);
      setCurrentWordIndex(0);
    }
  }, [currentWordIndex, foundWords, onWin]);
  
  const wrapWord = useCallback((word: string) => {
    const isCurrentWord = word.toLowerCase() === MAGIC_WORDS[currentWordIndex];
    const isFound = foundWords.includes(word.toLowerCase());
    
    return (
      <HiddenWord
        isActive={isCurrentWord && !isFound}
        onClick={() => handleWordClick(word.toLowerCase())}
      >
        {word}
      </HiddenWord>
    );
  }, [currentWordIndex, foundWords, handleWordClick]);

  // Render a hidden container to maintain React component requirements
  return (
    <div style={{ display: 'none' }}>
      {MAGIC_WORDS.map((word, index) => (
        <HiddenWord key={index} isActive={false}>{word}</HiddenWord>
      ))}
    </div>
  );
};

export const useScrollGame = (onWin: () => void): ScrollGameHelpers => {
  const [Component] = useState(() => () => <ScrollGame onWin={onWin} />);
  return {
    wrapWord: (word: string) => Component().props.children[0]
  };
};

export default ScrollGame;
