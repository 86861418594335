import React, { useState, useEffect, useCallback } from 'react';
import styled, { keyframes } from 'styled-components';
import ShakeGame from './games/ShakeGame';
import ScrollGame, { useScrollGame } from './games/ScrollGame';
import MoonGame from './games/MoonGame';
import { getGameState, verifyWin, submitWallet } from './gameService';

// Game Types
type GameType = 'konami' | 'pixel' | 'shake' | 'scroll' | 'moon' | 'brick';

// Interfaces
interface GameState {
  isActive: boolean;
  score: number;
  attempts: number;
  hasWon: boolean;
}

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const Container = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 15px;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 10px;
  color: #4CAF50;
  z-index: 9998;
  animation: ${fadeIn} 0.3s ease;
  border: 1px solid #4CAF50;
  box-shadow: 0 0 10px rgba(76, 175, 80, 0.3);
`;

const GameStatus = styled.div`
  position: fixed;
  top: 80px;
  left: 20px;
  padding: 15px;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 10px;
  color: #4CAF50;
  z-index: 9998;
  animation: ${fadeIn} 0.3s ease;
  border: 1px solid #4CAF50;
  box-shadow: 0 0 10px rgba(76, 175, 80, 0.3);
  font-family: 'Courier New', monospace;
`;

const pulseArrow = keyframes`
  0% { transform: scale(1) rotate(-90deg); opacity: 0.7; }
  50% { transform: scale(1.2) rotate(-90deg); opacity: 1; }
  100% { transform: scale(1) rotate(-90deg); opacity: 0.7; }
`;

const rainPiggy = keyframes`
  0% {
    transform: translateY(-20vh) rotate(0deg);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
    transform: translateY(70vh) rotate(360deg);
  }
  100% {
    transform: translateY(100vh) rotate(360deg);
    opacity: 0;
  }
`;

const PiggyContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: 9997;
`;

const Piggy = styled.div<{ delay: number; position: number; fallDuration: number }>`
  position: absolute;
  top: -50px;
  left: ${props => props.position}%;
  font-size: 2rem;
  animation: ${rainPiggy} ${props => props.fallDuration}s linear ${props => props.delay}s;
  z-index: 9997;
`;

const ArrowPrompt = styled.div`
  position: fixed;
  bottom: 40px;
  right: 40px;
  padding: 20px;
  z-index: 9998;
  display: flex;
  align-items: center;
  gap: 15px;
`;

const GoldArrow = styled.div<{ isPulsing: boolean }>`
  font-size: 3rem;
  color: gold;
  animation: ${props => props.isPulsing ? pulseArrow : 'none'} 1.5s ease-in-out infinite;
  cursor: pointer;
  text-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
  transform: rotate(-90deg);
  
  &:hover {
    transform: ${props => props.isPulsing ? 'scale(1.2) rotate(-90deg)' : 'rotate(-90deg)'};
  }
`;

// Game Components
const KonamiGame: React.FC<{ onWin: () => void }> = ({ onWin }) => {
  const [sequence, setSequence] = useState<string[]>([]);
  const konamiCode = ['ArrowUp'];

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      const key = event.key.toLowerCase();
      setSequence([key]);
      
      if (key === 'arrowup') {
        onWin();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [onWin]);

  return null;
};

const glowAnimation = keyframes`
  0% { box-shadow: 0 0 5px #4CAF50; }
  50% { box-shadow: 0 0 20px #4CAF50; }
  100% { box-shadow: 0 0 5px #4CAF50; }
`;

const PixelContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
`;

const Pixel = styled.div<{ isWinner?: boolean }>`
  position: absolute;
  width: ${props => props.isWinner ? '4px' : '2px'};
  height: ${props => props.isWinner ? '4px' : '2px'};
  background: ${props => props.isWinner ? 'rgba(76, 175, 80, 0.4)' : 'rgba(76, 175, 80, 0.1)'};
  cursor: pointer;
  border-radius: 50%;
  pointer-events: auto;
  transition: all 0.3s ease;
  &:hover {
    transform: scale(2);
    background: rgba(76, 175, 80, ${props => props.isWinner ? '0.8' : '0.3'});
  }
`;

const PixelHuntGame: React.FC<{ onWin: () => void }> = ({ onWin }) => {
  const [pixels, setPixels] = useState<Array<{ x: number; y: number; isWinner: boolean }>>([]);
  
  useEffect(() => {
    const generatePixels = () => {
      const newPixels = [];
      const numPixels = 20; // Total number of pixels
      const winnerIndex = Math.floor(Math.random() * numPixels);
      
      for (let i = 0; i < numPixels; i++) {
        newPixels.push({
          x: Math.random() * (window.innerWidth - 50) + 25,
          y: Math.random() * (window.innerHeight - 150) + 100, // Avoid header area
          isWinner: i === winnerIndex
        });
      }
      setPixels(newPixels);
    };

    generatePixels();
    const interval = setInterval(generatePixels, 30000); // Regenerate every 30 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <PixelContainer>
      {pixels.map((pixel, index) => (
        <Pixel
          key={index}
          style={{ left: pixel.x, top: pixel.y }}
          isWinner={pixel.isWinner}
          onClick={() => pixel.isWinner && onWin()}
        />
      ))}
    </PixelContainer>
  );
};

// Brick Game Component
const BrickGameContainer = styled.div`
  position: relative;
  margin: 20px auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;
  
  @media (max-width: 600px) {
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 2/3;
  }
`;

const PiggyWatermark = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 150px;
  opacity: 0.1;
  pointer-events: none;
  user-select: none;
  z-index: 0;
`;

const StartOverlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #4CAF50;
  text-align: center;
  width: 80%;
  z-index: 2;
  pointer-events: none;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`;

const AutoPlayIndicator = styled.div`
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: #4CAF50;
  font-size: 14px;
  z-index: 2;
  pointer-events: none;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
`;

const Ball = styled.div`
  position: absolute;
  width: 15px;
  height: 15px;
  background: #4CAF50;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

const Paddle = styled.div`
  position: absolute;
  width: 200px;
  height: 20px;
  background: #2196F3;
  border-radius: 10px;
  transform: translateX(-50%);
  z-index: 2;
`;

const Brick = styled.div<{ broken: boolean; health: number }>`
  position: absolute;
  width: 60px;
  height: 20px;
  background: ${props => 
    props.broken ? 'transparent' : 
    props.health === 3 ? '#FF5722' :
    props.health === 2 ? '#FF9800' :
    '#FFC107'};
  border-radius: 5px;
  transition: background-color 0.3s;
  opacity: ${props => props.broken ? 0 : (0.4 + props.health * 0.2)};
  z-index: 2;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, 
      transparent ${props => (3 - props.health) * 33}%, 
      rgba(255, 255, 255, 0.1) ${props => (3 - props.health) * 33 + 1}%);
    border-radius: inherit;
  }
`;

const BrickGame: React.FC<{ onWin: () => void; isWinnerMode: boolean }> = ({ onWin, isWinnerMode }) => {
  // Game difficulty constants
  const DIFFICULTY_SETTINGS = {
    easy: {
      ballSpeed: { x: 4, y: 4 },
      paddleWidth: 200,
      brickHealth: 2,
      winChance: 0.15
    },
    medium: {
      ballSpeed: { x: 5, y: 5 },
      paddleWidth: 150,
      brickHealth: 3,
      winChance: 0.10
    },
    hard: {
      ballSpeed: { x: 6, y: 6 },
      paddleWidth: 100,
      brickHealth: 4,
      winChance: 0.05
    },
    impossible: {
      ballSpeed: { x: 7, y: 7 },
      paddleWidth: 80,
      brickHealth: 5,
      winChance: 0.01
    }
  };

  // Game state
  const [difficulty, setDifficulty] = useState<keyof typeof DIFFICULTY_SETTINGS>('hard');
  const settings = DIFFICULTY_SETTINGS[difficulty];
  const [paddleX, setPaddleX] = useState(160);
  const [ballPos, setBallPos] = useState({ x: 195, y: 300 });
  const [ballSpeed, setBallSpeed] = useState(settings.ballSpeed);
  const [ballDir, setBallDir] = useState({ x: 1, y: -1 });
  const [bricks, setBricks] = useState(Array(12).fill(false));
  const [brickHealth, setBrickHealth] = useState(Array(12).fill(settings.brickHealth));
  const [gameStarted, setGameStarted] = useState(false);
  const [autoPlay, setAutoPlay] = useState(false);
  const [touchStartX, setTouchStartX] = useState<number | null>(null);
  const [initialPaddleX, setInitialPaddleX] = useState<number>(0);
  const gameContainerRef = React.createRef<HTMLDivElement>();

  // Constants
  const GAME_WIDTH = 400;
  const GAME_HEIGHT = 600;
  const BALL_SIZE = 15;
  const PADDLE_WIDTH = settings.paddleWidth;
  const PADDLE_HEIGHT = 20;
  const PADDLE_Y = GAME_HEIGHT - 40;
  const PADDLE_SPEED = 15;

  // Reset game with current difficulty
  const resetGame = useCallback(() => {
    setBallPos({ x: GAME_WIDTH / 2, y: GAME_HEIGHT / 2 });
    setBallSpeed(settings.ballSpeed);
    setBallDir({ x: 1, y: -1 });
    setBrickHealth(Array(12).fill(settings.brickHealth));
    setPaddleX(GAME_WIDTH / 2 - PADDLE_WIDTH / 2);
    setGameStarted(false);
    setAutoPlay(false);
  }, [settings, PADDLE_WIDTH]);

  // Initialize game when difficulty changes
  useEffect(() => {
    resetGame();
  }, [difficulty, resetGame]);

  // Handle keyboard controls
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!gameStarted || autoPlay) return;

      switch (e.key) {
        case 'ArrowLeft':
        case 'Left':
          setPaddleX(prev => Math.max(0, prev - PADDLE_SPEED));
          break;
        case 'ArrowRight':
        case 'Right':
          setPaddleX(prev => Math.min(GAME_WIDTH - PADDLE_WIDTH, prev + PADDLE_SPEED));
          break;
        case 'a':
          setAutoPlay(prev => !prev);
          break;
        case ' ':
          if (!gameStarted) setGameStarted(true);
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [gameStarted, autoPlay]);

  // Mouse movement with improved boundary handling
  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (gameStarted && !autoPlay && gameContainerRef.current) {
        const rect = gameContainerRef.current.getBoundingClientRect();
        const scale = GAME_WIDTH / rect.width;
        const relativeX = (e.clientX - rect.left) * scale;
        
        // Center the paddle on the mouse position
        let newX = relativeX - (PADDLE_WIDTH / 2);
        
        // Strict boundary checking
        newX = Math.max(0, Math.min(GAME_WIDTH - PADDLE_WIDTH, newX));
        
        setPaddleX(newX);
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, [gameStarted, autoPlay]);

  // Auto-play with improved tracking
  const updateAutoPlay = useCallback((ballX: number) => {
    if (autoPlay) {
      const targetX = ballX - (PADDLE_WIDTH / 2);
      const boundedTargetX = Math.max(0, Math.min(GAME_WIDTH - PADDLE_WIDTH, targetX));
      setPaddleX(prev => {
        const diff = boundedTargetX - prev;
        return prev + diff * 0.15; // Smooth movement
      });
    }
  }, [autoPlay]);

  // Game loop with improved paddle handling
  useEffect(() => {
    if (!gameStarted) return;

    const gameLoop = setInterval(() => {
      setBallPos(prev => {
        const next = { 
          x: prev.x + (ballSpeed.x * ballDir.x), 
          y: prev.y + (ballSpeed.y * ballDir.y)
        };

        // Update auto-play paddle position
        updateAutoPlay(next.x);

        // Wall collisions
        if (next.x <= 0 || next.x >= GAME_WIDTH - BALL_SIZE) {
          setBallDir(prev => ({ ...prev, x: -prev.x }));
          next.x = Math.max(0, Math.min(GAME_WIDTH - BALL_SIZE, next.x));
        }
        if (next.y <= 0) {
          setBallDir(prev => ({ ...prev, y: -prev.y }));
          next.y = 0;
        }

        // Paddle collision with improved detection
        const ballCenterX = next.x + BALL_SIZE / 2;
        const ballBottom = next.y + BALL_SIZE;
        const paddleCenterX = paddleX + PADDLE_WIDTH / 2;
        
        if (ballBottom >= PADDLE_Y && 
            ballBottom <= PADDLE_Y + PADDLE_HEIGHT && 
            next.x + BALL_SIZE >= paddleX && 
            next.x <= paddleX + PADDLE_WIDTH) {
          
          // Calculate reflection angle based on hit position
          const hitPosition = (ballCenterX - paddleCenterX) / (PADDLE_WIDTH / 2);
          const angle = hitPosition * (Math.PI / 3); // Max 60-degree angle
          
          // Set new direction while maintaining speed
          const newSpeedX = Math.sin(angle) * Math.sqrt(ballSpeed.x * ballSpeed.x + ballSpeed.y * ballSpeed.y);
          const newSpeedY = -Math.abs(Math.cos(angle) * Math.sqrt(ballSpeed.x * ballSpeed.x + ballSpeed.y * ballSpeed.y));
          
          setBallSpeed({ x: newSpeedX, y: newSpeedY });
          setBallDir({ x: 1, y: 1 }); // Reset direction multipliers
          
          next.y = PADDLE_Y - BALL_SIZE - 1; // Prevent sticking
          return next;
        }

        // Bottom boundary
        if (next.y >= GAME_HEIGHT - BALL_SIZE) {
          if (isWinnerMode) {
            setBallDir(prev => ({ ...prev, y: -Math.abs(prev.y) }));
            next.y = GAME_HEIGHT - BALL_SIZE;
          } else {
            setGameStarted(false);
            resetGame();
            return { x: GAME_WIDTH / 2, y: GAME_HEIGHT / 2 };
          }
        }

        // Brick collisions with improved accuracy
        bricks.forEach((broken, i) => {
          if (!broken) {
            const brickX = (i % 4) * 95 + 10;
            const brickY = Math.floor(i / 4) * 35 + 10;
            const BRICK_WIDTH = 60;
            const BRICK_HEIGHT = 20;

            // Expanded collision box for more forgiving hits
            const COLLISION_MARGIN = 2;
            if (next.x + BALL_SIZE + COLLISION_MARGIN >= brickX && 
                next.x - COLLISION_MARGIN <= brickX + BRICK_WIDTH &&
                next.y + BALL_SIZE + COLLISION_MARGIN >= brickY && 
                next.y - COLLISION_MARGIN <= brickY + BRICK_HEIGHT) {
              
              handleBrickCollision(i);

              // Determine collision side with improved accuracy
              const ballCenterX = next.x + BALL_SIZE / 2;
              const ballCenterY = next.y + BALL_SIZE / 2;
              const brickCenterX = brickX + BRICK_WIDTH / 2;
              const brickCenterY = brickY + BRICK_HEIGHT / 2;

              const dx = ballCenterX - brickCenterX;
              const dy = ballCenterY - brickCenterY;

              // More precise bounce angle calculation
              if (Math.abs(dx) * BRICK_HEIGHT > Math.abs(dy) * BRICK_WIDTH) {
                setBallDir(prev => ({ ...prev, x: Math.sign(dx) }));
              } else {
                setBallDir(prev => ({ ...prev, y: Math.sign(dy) }));
              }
            }
          }
        });

        return next;
      });
    }, isWinnerMode ? 32 : 16);

    return () => clearInterval(gameLoop);
  }, [gameStarted, paddleX, ballDir, bricks, onWin, isWinnerMode, autoPlay]);

  // Handle touch events
  const handleTouchStart = (e: React.TouchEvent) => {
    if (!autoPlay) {
      const touch = e.touches[0];
      if (touch && gameContainerRef.current) {
        const rect = gameContainerRef.current.getBoundingClientRect();
        setTouchStartX(touch.clientX - rect.left);
        setInitialPaddleX(paddleX);
        if (!gameStarted) setGameStarted(true);
      }
    }
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!autoPlay && touchStartX !== null && gameContainerRef.current) {
      const touch = e.touches[0];
      const rect = gameContainerRef.current.getBoundingClientRect();
      const currentX = touch.clientX - rect.left;
      const deltaX = currentX - touchStartX;
      const newX = Math.max(0, Math.min(GAME_WIDTH - PADDLE_WIDTH, initialPaddleX + deltaX));
      setPaddleX(newX);
    }
  };

  const handleTouchEnd = () => {
    setTouchStartX(null);
  };

  // Update brick collision logic
  const handleBrickCollision = (brickIndex: number) => {
    setBrickHealth(prev => {
      const newHealth = [...prev];
      newHealth[brickIndex] = Math.max(0, newHealth[brickIndex] - 1);
      
      // Update broken state if brick is completely destroyed
      if (newHealth[brickIndex] === 0) {
        setBricks(prev => {
          const next = [...prev];
          next[brickIndex] = true;
          if (next.every(b => b)) {
            onWin();
          }
          return next;
        });
      }
      return newHealth;
    });
  };

  // Game container styles
  const containerStyle: React.CSSProperties = {
    position: 'relative',
    width: `${GAME_WIDTH}px`,
    height: `${GAME_HEIGHT}px`,
    background: 'rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    touchAction: 'none',
    userSelect: 'none',
    cursor: 'pointer', // Show pointer cursor for clickable areas
  };

  return (
    <BrickGameContainer 
      ref={gameContainerRef}
      onClick={() => !gameStarted && setGameStarted(true)}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      style={containerStyle}
    >
      <DifficultySelector>
        {Object.keys(DIFFICULTY_SETTINGS).map((level) => (
          <DifficultyButton
            key={level}
            active={difficulty === level}
            onClick={(e) => {
              e.stopPropagation(); // Prevent game start when clicking difficulty
              setDifficulty(level as keyof typeof DIFFICULTY_SETTINGS);
            }}
          >
            {level.charAt(0).toUpperCase() + level.slice(1)}
          </DifficultyButton>
        ))}
      </DifficultySelector>
      <PiggyWatermark>🐷</PiggyWatermark>
      
      {!gameStarted && (
        <StartOverlay>
          <div style={{ fontSize: '20px', marginBottom: '10px' }}>
            {/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
              ? 'Tap to Start'
              : 'Click or Press Space to Start'}
          </div>
          <div style={{ fontSize: '14px', opacity: 0.8 }}>
            {isWinnerMode ? '🌟 Winner Mode: Extra Easy!' : (
              /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
                ? 'Touch and drag to move'
                : 'Use Mouse, Arrow Keys, or A for auto-play'
            )}
          </div>
        </StartOverlay>
      )}
      
      <Paddle style={{ left: paddleX, bottom: '20px' }} />
      <Ball style={{ left: ballPos.x, top: ballPos.y }} />
      {bricks.map((broken, i) => (
        <Brick
          key={i}
          broken={broken}
          health={brickHealth[i]}
          style={{
            left: (i % 4) * 95 + 10,
            top: Math.floor(i / 4) * 35 + 10
          }}
        />
      ))}
      {autoPlay && (
        <AutoPlayIndicator>
          🤖 Auto-play Active
        </AutoPlayIndicator>
      )}
    </BrickGameContainer>
  );
};

// Main Component
const SecretPiggy: React.FC = () => {
  const [currentGame, setCurrentGame] = useState<GameType | null>(null);
  const [gameState, setGameState] = useState<GameState>({
    isActive: false,
    score: 0,
    attempts: 0,
    hasWon: false
  });
  const [showPiggies, setShowPiggies] = useState(false);
  const [showArrow, setShowArrow] = useState(false);
  const [isArrowPulsing, setIsArrowPulsing] = useState(true);
  const [piggyCount, setPiggyCount] = useState(0);
  const maxPiggies = 30; // Reduced max piggies for better spread
  const [isWinner] = useState(() => Math.random() < 0.1);

  const handleWin = useCallback(() => {
    setGameState(prev => ({ ...prev, hasWon: true }));
  }, []);

  const getGameStatusText = () => {
    if (!currentGame) return 'No active game';
    
    switch (currentGame) {
      case 'konami':
        return `🎯 Press the Konami Code sequence`;
      case 'pixel':
        return '🔍 Find and click the special pixel!\nHint: One pixel glows brighter on hover';
      case 'shake':
        return `📱 Shake or rapidly press spacebar`;
      case 'scroll':
        return `📜 Find hidden words in the text`;
      case 'moon':
        return `🚀 Guide your rocket to the moon`;
      case 'brick':
        return `🧱 Break all bricks to win!\nMove mouse to control paddle\nPress A for auto-play`;
      default:
        return 'Game loading...';
    }
  };

  // Handle hash changes and clicks
  useEffect(() => {
    const handleHashChange = () => {
      if (window.location.hash === '#vault') {
        // Show arrow when Secret Piggy Bank is clicked
        setShowArrow(true);
        setIsArrowPulsing(true);
        setShowPiggies(false);
        setPiggyCount(0);
      } else {
        // Reset when navigating away
        setShowArrow(false);
        setShowPiggies(false);
        setPiggyCount(0);
        setIsArrowPulsing(true);
      }
    };

    // Listen for hash changes
    window.addEventListener('hashchange', handleHashChange);

    // Also check initial hash in case user navigates directly to #vault
    if (window.location.hash === '#vault') {
      setShowArrow(true);
      setIsArrowPulsing(true);
    }

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
      setShowArrow(false);
      setShowPiggies(false);
      setPiggyCount(0);
      setIsArrowPulsing(true);
    };
  }, []);

  // Hide arrow after piggies finish falling
  useEffect(() => {
    if (showPiggies && piggyCount > 0) {
      // Wait for longest possible animation (12s fall + 5s max delay)
      const timer = setTimeout(() => {
        setShowArrow(false);
      }, 17000);
      return () => clearTimeout(timer);
    }
  }, [showPiggies, piggyCount]);

  const handleArrowClick = () => {
    if (piggyCount < maxPiggies) {
      setShowPiggies(true);
      setPiggyCount(prev => prev + 5); // Add fewer piggies each click
      setIsArrowPulsing(false); // Stop pulsing after click
    }
  };

  return (
    <>
      {process.env.NODE_ENV === 'development' && (
        <>
          <GameStatus>
            <div>Game Status:</div>
            <pre>{getGameStatusText()}</pre>
          </GameStatus>

          {currentGame && (
            <Container>
              {currentGame === 'konami' && <KonamiGame onWin={handleWin} />}
              {currentGame === 'pixel' && <PixelHuntGame onWin={handleWin} />}
              {currentGame === 'brick' && <BrickGame onWin={handleWin} isWinnerMode={isWinner} />}
              {currentGame === 'shake' && <ShakeGame onWin={handleWin} />}
              {currentGame === 'scroll' && <ScrollGame onWin={handleWin} />}
              {currentGame === 'moon' && <MoonGame onWin={handleWin} />}
            </Container>
          )}
        </>
      )}

      {/* Show arrow and piggies */}
      {showArrow && (
        <ArrowPrompt>
          <GoldArrow onClick={handleArrowClick} isPulsing={isArrowPulsing}>➤</GoldArrow>
        </ArrowPrompt>
      )}

      {showPiggies && (
        <PiggyContainer>
          {Array.from({ length: Math.min(piggyCount, maxPiggies) }).map((_, i) => (
            <Piggy
              key={`${i}-${piggyCount}`}
              delay={Math.random() * 5} // More spread in start times
              position={Math.random() * 100}
              fallDuration={10 + Math.random() * 2} // Random duration between 10-12s
            >
              🐷
            </Piggy>
          ))}
        </PiggyContainer>
      )}
    </>
  );
};

const DifficultySelector = styled.div`
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
  z-index: 100;
`;

const DifficultyButton = styled.button<{ active: boolean }>`
  background: ${props => props.active ? '#4CAF50' : '#2d2d2d'};
  color: white;
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  opacity: ${props => props.active ? 1 : 0.7};
  transition: all 0.2s ease;

  &:hover {
    opacity: 1;
    transform: scale(1.05);
  }
`;

export type { GameType };
export { useScrollGame as useSecretScrollGame };
export default SecretPiggy;
