import React from 'react';
import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

const Section = styled.section`
  background: var(--background-gradient-1);
  color: var(--text-color);
  position: relative;
  overflow: hidden;
  margin-top: 0; /* Ensure no top margin */
`;

const DisclaimerSection = styled.div`
  margin-top: 0; /* Remove any top margin */
  padding: 60px 0;
  background: linear-gradient(135deg,
    rgba(153, 69, 255, 0.1),  /* sol-purple */
    rgba(0, 194, 255, 0.1)    /* sol-blue */
  );
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-top: 1px solid rgba(255, 255, 255, 0.03);
  
  h3 {
    animation: ${pulse} 2s ease-in-out infinite;
    background: linear-gradient(135deg, var(--sol-purple), var(--sol-blue));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Title = styled.h3`
  text-align: center;
  font-size: 2rem;
  margin-bottom: 30px;
  background: linear-gradient(135deg, #FF6B6B, #FFE66D);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Text = styled.p`
  text-align: center;
  font-size: 1.1rem;
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.8);
  max-width: 900px;
  margin: 0 auto;
  
  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.6;
  }
`;

const FooterSection = styled.footer`
  padding: 30px 0;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  margin-top: 0; /* Ensure no top margin */
  border-top: 1px solid rgba(255, 255, 255, 0.03);
`;

const Copyright = styled.p`
  text-align: center;
  color: var(--text-secondary);
  font-size: 0.9rem;
  
  span {
    color: var(--sol-green);
  }
`;

const Footer: React.FC = () => {
  return (
    <Section>
      <DisclaimerSection>
        <Container>
          <Title> FOMO Responsibly </Title>
          <Text>
            $NotSol is a meme token created purely for entertainment purposes. This is not financial advice, and you should never invest more than you can afford to lose. Remember: Past FOMO does not guarantee future FOMO. Always DYOR (Do Your Own Research) and never invest based solely on cute pig memes (no matter how tempting).
          </Text>
        </Container>
      </DisclaimerSection>
      <FooterSection>
        <Container>
          <Copyright>
            {new Date().getFullYear()} $NotSol. All rights reserved. Built by fellow FOMOers, for FOMOers.
          </Copyright>
        </Container>
      </FooterSection>
    </Section>
  );
};

export default Footer;
