import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const unrollScroll = keyframes`
  0% {
    transform: perspective(1000px) rotateX(90deg) translateY(-100%);
    max-height: 0;
  }
  50% {
    transform: perspective(1000px) rotateX(45deg) translateY(-50%);
    max-height: 50vh;
  }
  100% {
    transform: perspective(1000px) rotateX(0) translateY(0);
    max-height: 90vh;
  }
`;

const breakSeal = keyframes`
  0% {
    transform: scale(1) rotate(0);
    opacity: 1;
  }
  50% {
    transform: scale(1.2) rotate(10deg);
    opacity: 0.5;
  }
  100% {
    transform: scale(0) rotate(45deg);
    opacity: 0;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const revealText = keyframes`
  from {
    transform: translateY(20px);
    opacity: 0;
    height: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
    height: auto;
  }
`;

const createJaggedEdge = () => {
  let path = 'polygon(';
  const steps = 40; // number of jagged points
  const variance = 0.5; // how jagged the edges are (0-1)
  
  for (let i = 0; i <= steps; i++) {
    const x = (i / steps) * 100;
    const yTop = i % 2 === 0 ? 0 : variance;
    const yBottom = i % 2 === 0 ? 100 - variance : 100;
    path += `${x}% ${yTop}%, `;
  }
  
  // Add bottom edge points
  for (let i = steps; i >= 0; i--) {
    const x = (i / steps) * 100;
    const y = i % 2 === 0 ? 100 : 100 - variance;
    path += `${x}% ${y}%${i > 0 ? ', ' : ')'}`;
  }
  
  return path;
};

const ScrollContainer = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.92);
  backdrop-filter: blur(12px);
  display: ${props => props.isOpen ? 'flex' : 'none'};
  justify-content: center;
  align-items: flex-start;
  z-index: 9999;
  padding: 1rem;
  overflow-y: auto;
  perspective: 1000px;
`;

const Scroll = styled.div<{ isOpen: boolean }>`
  max-width: 800px;
  width: 95%;
  background: #D4C5A9;
  background-image: 
    linear-gradient(to right, rgba(0,0,0,0.05) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(0,0,0,0.05) 1px, transparent 1px),
    linear-gradient(to right, rgba(139,69,19,0.1), transparent 50%, rgba(139,69,19,0.1));
  background-size: 20px 20px, 20px 20px, 100% 100%;
  border-radius: 10px;
  padding: 2.5rem 2rem;
  position: relative;
  box-shadow: 
    0 0 50px rgba(0, 0, 0, 0.7),
    inset 0 0 30px rgba(139,69,19,0.3);
  transform-origin: top center;
  animation: ${props => props.isOpen ? unrollScroll : 'none'} 1.8s cubic-bezier(0.4, 0, 0.2, 1);
  margin: 2rem auto;
  border: 1px solid rgba(139,69,19,0.3);
  overflow: hidden;
  clip-path: ${createJaggedEdge()};
  
  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 40px;
    left: 0;
  }

  &::before {
    top: 0;
    background: linear-gradient(to bottom, rgba(139, 69, 19, 0.4), transparent);
  }

  &::after {
    bottom: 0;
    background: linear-gradient(to top, rgba(139, 69, 19, 0.4), transparent);
  }

  @media (max-width: 480px) {
    padding: 2rem 1.5rem;
  }
`;

const WaxSeal = styled.div<{ isOpen: boolean }>`
  width: 80px;
  height: 80px;
  background: linear-gradient(45deg, #8B0000, #DC1FFF);
  border-radius: 50%;
  position: absolute;
  top: -40px;
  right: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: 'Righteous', cursive;
  font-size: 1.2rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  animation: ${props => props.isOpen ? breakSeal : 'none'} 1s ease-out forwards;
  
  &::before {
    content: '$NS';
    opacity: 0.8;
  }
`;

const ScrollContent = styled.div`
  color: #2C1810;
  padding: 0.5rem;
  font-family: 'Fredoka', sans-serif;
  max-height: calc(100vh - 6rem);
  overflow-y: auto;
  letter-spacing: 0.02em;
  opacity: 0;
  animation: ${fadeIn} 0.5s ease-out 1.5s forwards;
  
  &::-webkit-scrollbar {
    width: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: rgba(139, 69, 19, 0.1);
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: rgba(139, 69, 19, 0.3);
    border-radius: 4px;
  }
`;

const Title = styled.h2`
  font-family: 'Righteous', cursive;
  font-size: clamp(1.5rem, 5vw, 2.2rem);
  text-align: center;
  margin-bottom: clamp(1rem, 3vw, 1.5rem);
  color: #2C1810;
  text-shadow: 1px 1px 2px rgba(139, 69, 19, 0.4);
  animation: ${revealText} 0.8s ease-out 1.6s forwards;
  opacity: 0;
  height: 0;
  padding: 0 clamp(0.5rem, 2vw, 1rem);
  line-height: 1.2;

  @media (max-width: 480px) {
    margin-top: -0.5rem;
  }
`;

const StoryText = styled.p<{ delay: number }>`
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  animation: ${revealText} 0.8s ease-out forwards;
  animation-delay: ${props => props.delay}s;
  opacity: 0;
  height: 0;
  text-align: justify;
  color: #2C1810;
  font-weight: 500;
  text-shadow: 0.5px 0.5px 0px rgba(139, 69, 19, 0.2);
  font-family: 'Crimson Text', serif;

  @media (max-width: 768px) {
    font-size: 0.95rem;
  }
`;

const Commandments = styled.div`
  background: rgba(139, 69, 19, 0.08);
  border-radius: 8px;
  padding: 1.2rem;
  margin: 1.5rem 0;
  animation: ${revealText} 0.8s ease-out 2.5s forwards;
  border: 1px solid rgba(139, 69, 19, 0.15);
  opacity: 0;
  height: 0;

  p {
    font-size: 1rem;
    margin-bottom: 0.8rem;
    display: flex;
    align-items: center;
    gap: 0.8rem;
    color: #2C1810;
    font-family: 'Crimson Text', serif;
    font-weight: 500;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Tokenomics = styled.div`
  margin: 1.5rem 0;
  animation: ${revealText} 0.8s ease-out 3s forwards;
  padding: 0 0.5rem;
  opacity: 0;
  height: 0;

  p {
    font-size: 1rem;
    margin-bottom: 0.8rem;
    padding-left: 1.2rem;
    position: relative;
    color: #2C1810;
    font-family: 'Crimson Text', serif;
    font-weight: 500;

    &::before {
      content: '•';
      position: absolute;
      left: 0;
      color: #2C1810;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const ScrollFooter = styled.p`
  text-align: center;
  font-style: italic;
  margin-top: 2rem;
  color: #2C1810;
  font-size: 0.95rem;
  animation: ${revealText} 0.8s ease-out 3.5s forwards;
  font-family: 'Crimson Text', serif;
  font-weight: 500;
  opacity: 0;
  height: 0;
`;

const CloseButton = styled.button`
  position: fixed;
  top: 2rem;
  right: 2rem;
  background: none;
  border: none;
  color: #D4C5A9;
  font-size: 2rem;
  cursor: pointer;
  z-index: 1001;
  transition: transform 0.3s ease;
  text-shadow: 0 0 10px rgba(0,0,0,0.5);

  &:hover {
    transform: scale(1.1);
  }
`;

const WhitepaperSection = styled.section`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Whitepaper: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleHashChange = () => {
      if (window.location.hash === '#whitepaper') {
        setIsOpen(true);
      }
    };

    window.addEventListener('hashchange', handleHashChange);
    if (window.location.hash === '#whitepaper') {
      setIsOpen(true);
    }

    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    window.location.hash = '';
  };

  return (
    <WhitepaperSection id="whitepaper">
      <ScrollContainer isOpen={isOpen}>
        <CloseButton onClick={handleClose}>×</CloseButton>
        <Scroll isOpen={isOpen}>
          <WaxSeal isOpen={isOpen} />
          <ScrollContent>
            <Title>📜 The Sacred Text of $NotSol 📜</Title>
            
            <StoryText delay={1.7}>
              In the beginning, there was SOL. And lo, the blockchain was fast and the fees were low.
            </StoryText>
            
            <StoryText delay={1.9}>
              But there lived a pig, who was not like other pigs. For he had missed the great SOL run of '21, 
              and his heart was heavy with the weight of missed gains.
            </StoryText>
            
            <StoryText delay={2.1}>
              "Fear not," spoke the wise old pig of the farm. "For in the depths of the bear market, 
              a new token shall rise. Its name shall be $NotSol, and it shall bring joy to all who missed their chance."
            </StoryText>
            
            <StoryText delay={2.3}>And so it was written:</StoryText>
            
            <Commandments>
              <p>🐷 Thou shalt HODL with diamond hooves</p>
              <p>🚀 Thou shalt not paper hand at 2x</p>
              <p>💎 Thou shalt buy high and sell higher</p>
              <p>🌟 Thou shalt not FOMO... (but if thou must, FOMO responsibly)</p>
            </Commandments>
            
            <StoryText delay={2.7}>
              The prophecy speaks of a time when $NotSol shall rise above all memecoins, 
              when pigs shall finally fly, and when every holder shall be blessed with gains most holy.
            </StoryText>
            
            <StoryText delay={2.9}>Tokenomics (as foretold by the ancients):</StoryText>
            
            <Tokenomics>
              <p>Total Supply: 1,000,000,000 $NotSol</p>
              <p>The People's Share: "For every FOMOer shall receive their fair portion, distributed through the ways of the meme"</p>
              <p>The Sacred Pool: "As liquid as the tears of those who sold too early"</p>
              <p>The Meme Treasury: "For the creation and spreading of the dankest memes across the realm"</p>
              <p>Team's Blessing: 10% (blessed be the builders)</p>
            </Tokenomics>
            
            <ScrollFooter>
              And so it was written, in the year of our FOMO, 2024
            </ScrollFooter>
          </ScrollContent>
        </Scroll>
      </ScrollContainer>
    </WhitepaperSection>
  );
};

export default Whitepaper;
