import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faLaughSquint, faHandHoldingHeart } from '@fortawesome/free-solid-svg-icons';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const glassShine = keyframes`
  0% {
    background-position: -100% -100%;
  }
  100% {
    background-position: 200% 200%;
  }
`;

const Section = styled.section`
  padding: 40px 0;
  background: #1a1f3c;
  color: white;
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  perspective: 1000px;
`;

const Title = styled.h2`
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 40px;
  background: linear-gradient(135deg, #FF6B6B, #FFE66D);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  transform-style: preserve-3d;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  background: rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 20px;
  padding: 40px 30px;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.08);
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
  transform: perspective(1000px) rotateY(-5deg);
  box-shadow: 
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06),
    inset 0 0 20px rgba(255, 255, 255, 0.05);
  
  &:nth-child(2) {
    transform: perspective(1000px) rotateY(0deg);
  }
  
  &:nth-child(3) {
    transform: perspective(1000px) rotateY(5deg);
  }
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    height: 100%;
    background: linear-gradient(
      120deg,
      transparent,
      rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.05),
      transparent
    );
    transform: rotate(45deg);
    transition: 0.8s ease;
    opacity: 0;
  }

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 20px;
    padding: 1px;
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.05),
      transparent,
      transparent,
      rgba(255, 255, 255, 0.05)
    );
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }
  
  &:hover {
    transform: perspective(1000px) rotateY(0deg) translateY(-10px);
    background: rgba(255, 255, 255, 0.12);
    border: 1px solid rgba(255, 255, 255, 0.12);
    box-shadow: 
      0 20px 25px -5px rgba(0, 0, 0, 0.2),
      0 10px 10px -5px rgba(0, 0, 0, 0.1),
      inset 0 0 30px rgba(255, 255, 255, 0.05);
    
    &::before {
      left: 100%;
      opacity: 1;
      transition: 0.8s ease;
    }
    
    .icon-wrapper {
      animation: ${rotate} 10s linear infinite;
      background: rgba(255, 255, 255, 0.15);
      border: 1px solid rgba(255, 255, 255, 0.2);
    }
  }
`;

const IconWrapper = styled.div`
  font-size: 2.5rem;
  background: rgba(255, 255, 255, 0.1);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
  color: #FFE66D;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  
  &::before {
    content: '';
    position: absolute;
    inset: -1px;
    border-radius: 50%;
    padding: 1px;
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.1),
      transparent
    );
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    opacity: 0.7;
  }
`;

const CardTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 15px;
  background: linear-gradient(135deg, #FF6B6B, #FFE66D);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 10px rgba(255, 107, 107, 0.2);
`;

const CardText = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
`;

const FomoMovement: React.FC = () => {
  const movementItems = [
    {
      icon: faUsers,
      title: 'Community First',
      description: 'A community of fellow FOMOers supporting each other through every pump and dump'
    },
    {
      icon: faLaughSquint,
      title: 'Memes Forever',
      description: 'Where serious gains meet not-so-serious memes'
    },
    {
      icon: faHandHoldingHeart,
      title: 'WAGMI',
      description: "We're All Gonna Make It (eventually, probably, maybe)"
    }
  ];

  return (
    <Section>
      <Container>
        <Title>Join the FOMO Movement 🚀</Title>
        <Grid>
          {movementItems.map((item, index) => (
            <Card key={index}>
              <IconWrapper className="icon-wrapper">
                <FontAwesomeIcon icon={item.icon} />
              </IconWrapper>
              <CardTitle>{item.title}</CardTitle>
              <CardText>{item.description}</CardText>
            </Card>
          ))}
        </Grid>
      </Container>
    </Section>
  );
};

export default FomoMovement;
