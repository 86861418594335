import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const TestContainer = styled.div<{ isVisible: boolean }>`
  position: fixed;
  top: 20px;
  right: 20px;
  background: rgba(0, 0, 0, 0.95);
  padding: 20px;
  border-radius: 10px;
  color: white;
  z-index: 9999;
  display: ${props => props.isVisible ? 'block' : 'none'};
  border: 1px solid #4CAF50;
  box-shadow: 0 0 10px rgba(76, 175, 80, 0.5);
  min-width: 300px;
`;

const Button = styled.button<{ active?: boolean }>`
  background: ${props => props.active ? '#45a049' : '#4CAF50'};
  color: white;
  border: none;
  padding: 8px 16px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: calc(50% - 10px);
  &:hover { 
    background: #45a049;
    transform: scale(1.05);
  }
`;

const Status = styled.div`
  margin: 10px 0;
  padding: 8px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  text-align: center;
  font-size: 0.9em;
`;

const ButtonGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin: 10px 0;
`;

const Title = styled.h3`
  text-align: center;
  color: #4CAF50;
  margin-bottom: 15px;
  font-size: 1.2em;
`;

const Instructions = styled.div`
  font-size: 0.85em;
  margin-top: 15px;
  padding: 10px;
  background: rgba(76, 175, 80, 0.1);
  border-radius: 4px;
  border-left: 3px solid #4CAF50;
`;

// Only available in development
const GameTester: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [currentGame, setCurrentGame] = useState<string | null>(null);
  const [gameInstructions, setGameInstructions] = useState<string>('Select a game to test');

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.shiftKey && event.key.toLowerCase() === 'd') {
        console.log('Toggle tester visibility');
        setIsVisible(prev => !prev);
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    console.log('GameTester mounted, press Shift+D to toggle');

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  if (process.env.NODE_ENV !== 'development') {
    return null;
  }

  const getInstructions = (game: string): string => {
    switch (game) {
      case 'konami':
        return '🎯 Press: ↑ ↑ ↓ ↓ ← → ← → B A';
      case 'pixel':
        return '🔍 Move your mouse to find the invisible pixel';
      case 'shake':
        return '📱 Press SPACE rapidly or shake device';
      case 'scroll':
        return '📜 Find and click: moon → lambo → hodl → degen → wen';
      case 'moon':
        return '🚀 Use arrow keys to navigate to the moon';
      case 'brick':
        return '🏰 Move mouse to control paddle. Press A for auto-play!';
      default:
        return 'Select a game to test';
    }
  };

  const forceGame = (game: string) => {
    console.log('Setting game:', game);
    setCurrentGame(game);
    setGameInstructions(getInstructions(game));
    (window as any).__FORCE_GAME__ = game;
  };

  return (
    <TestContainer isVisible={isVisible}>
      <Title>🎮 Game Tester</Title>
      <Status>Current Game: {currentGame || 'None'}</Status>
      <ButtonGrid>
        <Button 
          active={currentGame === 'konami'}
          onClick={() => forceGame('konami')}>
          🎯 Konami Code
        </Button>
        <Button 
          active={currentGame === 'pixel'}
          onClick={() => forceGame('pixel')}>
          🔍 Pixel Hunt
        </Button>
        <Button 
          active={currentGame === 'shake'}
          onClick={() => forceGame('shake')}>
          📱 Shake Game
        </Button>
        <Button 
          active={currentGame === 'scroll'}
          onClick={() => forceGame('scroll')}>
          📜 Scroll Hunt
        </Button>
        <Button 
          active={currentGame === 'moon'}
          onClick={() => forceGame('moon')}>
          🚀 Moon Mission
        </Button>
        <Button 
          active={currentGame === 'brick'}
          onClick={() => forceGame('brick')}>
          🏰 Brick Break
        </Button>
        <Button 
          onClick={() => forceGame('random')}>
          🎲 Random
        </Button>
      </ButtonGrid>
      <Instructions>
        <strong>How to Play:</strong><br/>
        {gameInstructions}
      </Instructions>
      <Status>Press Shift+D to show/hide</Status>
    </TestContainer>
  );
};

export default GameTester;
