import React, { useState, useCallback } from 'react';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faTelegram } from '@fortawesome/free-brands-svg-icons';
import { faHandshake } from '@fortawesome/free-solid-svg-icons';

const NavContainer = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: var(--background-gradient-1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 1rem 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.03);
  border-image: linear-gradient(
    90deg,
    var(--sol-purple),
    var(--sol-blue)
  ) 1;
`;

const NavContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 2rem;

  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
  }
`;

const NavLinks = styled.ul<{ isOpen: boolean }>`
  display: flex;
  gap: 2rem;
  align-items: center;
  list-style: none;
  margin: 0;
  justify-content: center;

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: var(--background-gradient-1);
    padding: 1rem 0;
    gap: 1rem;
  }
`;

const NavLink = styled.a`
  color: var(--text-color);
  text-decoration: none;
  font-family: 'Fredoka', sans-serif;
  font-weight: 600;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  padding: 0.5rem;
  position: relative;

  &:hover {
    color: #00FFA3;
    text-shadow: 0 0 10px rgba(0, 255, 163, 0.5);
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background: linear-gradient(45deg, #00FFA3, #03E1FF);
    transition: width 0.3s ease;
    border-radius: 2px;
  }

  &:hover::after {
    width: 100%;
  }

  &.secret-link {
    color: #DC1FFF;
    text-shadow: 0 0 8px rgba(220, 31, 255, 0.3);

    &:hover {
      color: #FF1FB7;
    }
  }

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

const SocialLinks = styled.div<{ isOpen: boolean }>`
  display: flex;
  gap: 1.5rem;
  align-items: center;
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-self: end;

  @media (max-width: 768px) {
    margin-left: auto;
    gap: 0.5rem;
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;
  
  img {
    height: 48px;
    width: 48px;
    object-fit: contain;
    filter: drop-shadow(0 0 8px rgba(20, 241, 149, 0.3));
  }
  
  span {
    font-family: 'Press Start 2P', cursive;
    font-size: 1.4rem;
    background: linear-gradient(135deg, var(--sol-purple), var(--sol-blue));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  /* Maintain size on mobile */
  @media (max-width: 768px) {
    img {
      height: 48px;
      width: 48px;
    }
    span {
      font-size: 1.4rem;
    }
  }
`;

const PigPartnersButton = styled.button`
  background: var(--glass-bg);
  color: var(--sol-green);
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  cursor: pointer;
  font-family: 'Press Start 2P', cursive;
  font-size: 0.8rem;
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  @media (max-width: 768px) {
    padding: 0.5rem;
    font-size: 1.2rem;
    
    span {
      display: none;
    }
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 50px;
    padding: 1px;
    background: linear-gradient(
      135deg,
      var(--sol-purple),
      var(--sol-green)
    );
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  svg {
    font-size: 1rem;
    @media (max-width: 768px) {
      font-size: 1.2rem;
    }
  }

  &:hover {
    transform: translateY(-2px);
    color: var(--sol-blue);
    box-shadow: 0 0 15px rgba(153, 69, 255, 0.3);
  }

  &:active {
    transform: translateY(0);
  }
`;

const MenuToggle = styled.button<{ isOpen: boolean }>`
  display: none;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  position: relative;
  z-index: 1000;

  @media (max-width: 768px) {
    display: block;
  }

  span {
    display: block;
    width: 24px;
    height: 2px;
    background: ${({ isOpen }) => 
      isOpen 
        ? 'linear-gradient(45deg, #00FFA3, #DC1FFF)'
        : 'linear-gradient(45deg, #00FFA3, #03E1FF)'
    };
    transition: all 0.3s ease;
    transform-origin: center;
    border-radius: 4px;
    margin: 4px 0;

    &:nth-child(1) {
      transform: ${({ isOpen }) =>
        isOpen ? 'translateY(8px) rotate(45deg)' : 'none'};
    }

    &:nth-child(2) {
      opacity: ${({ isOpen }) => (isOpen ? '0' : '1')};
      transform: ${({ isOpen }) =>
        isOpen ? 'translateX(-20px)' : 'none'};
    }

    &:nth-child(3) {
      transform: ${({ isOpen }) =>
        isOpen ? 'translateY(-8px) rotate(-45deg)' : 'none'};
    }
  }
`;

const fallAnimation = keyframes`
  from {
    transform: translateY(-20px) rotate(0deg);
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  to {
    transform: translateY(100vh) rotate(360deg);
    opacity: 0;
  }
`;

const FallingPiggy = styled.div<{ delay: number, leftPos: number }>`
  position: fixed;
  top: -20px;
  left: ${props => props.leftPos}vw;
  font-size: 2rem;
  animation: ${fallAnimation} 4s ease-in forwards;
  animation-delay: ${props => props.delay}s;
  z-index: 999;
  pointer-events: none;
  opacity: 0;
`;

const Navigation: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [fallingPiggies, setFallingPiggies] = useState<Array<{ id: number; delay: number; leftPos: number }>>([]);
  const [canTriggerPiggies, setCanTriggerPiggies] = useState(true);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const triggerPiggies = useCallback(() => {
    if (!canTriggerPiggies) return;
    
    setCanTriggerPiggies(false);
    const newPiggies = Array.from({ length: 20 }, (_, index) => ({
      id: Date.now() + index,
      delay: Math.random() * 8,
      leftPos: Math.random() * 90 + 5
    }));
    
    setFallingPiggies(newPiggies);
    
    setTimeout(() => {
      setFallingPiggies([]);
      setCanTriggerPiggies(true);
    }, 12000);
  }, [canTriggerPiggies]);

  const handleSecretPiggyClick = () => {
    const element = document.getElementById('secretpiggy');
    if (element) {
      const headerOffset = 80;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
    setIsMenuOpen(false);
    triggerPiggies();
  };

  const scrollToElement = (elementId: string) => {
    const element = document.getElementById(elementId);
    if (element) {
      const headerOffset = 80; // Height of fixed header
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
      setIsMenuOpen(false);
    }
  };

  return (
    <NavContainer>
      {fallingPiggies.map(piggy => (
        <FallingPiggy 
          key={piggy.id} 
          delay={piggy.delay} 
          leftPos={piggy.leftPos}
        >
          🐷
        </FallingPiggy>
      ))}
      <NavContent>
        <Logo onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
          <img src="/images/logo.png" alt="$NotSol Logo" />
          <span>$NotSol</span>
        </Logo>

        <NavLinks isOpen={isMenuOpen}>
          <li><NavLink onClick={() => scrollToElement('home')}>Oink! (Home)</NavLink></li>
          <li><NavLink onClick={() => { window.location.hash = 'whitepaper'; setIsMenuOpen(false); }}>Whitepaper</NavLink></li>
          <li><NavLink onClick={handleSecretPiggyClick} className="secret-link">Secret Piggy Bank 🏦</NavLink></li>
        </NavLinks>

        <RightSection>
          <SocialLinks isOpen={isMenuOpen}>
            <div className="social-icons">
              <NavLink href="https://x.com/NotSolg" target="_blank" rel="noopener noreferrer" aria-label="X (Twitter)">
                <FontAwesomeIcon icon={faXTwitter} />
              </NavLink>
              <NavLink href="https://t.me/NotSolGuy" target="_blank" rel="noopener noreferrer" aria-label="Telegram">
                <FontAwesomeIcon icon={faTelegram} />
              </NavLink>
            </div>
            <PigPartnersButton onClick={() => scrollToElement('fellowship')}>
              <FontAwesomeIcon icon={faHandshake} />
              <span>Pig Partners</span>
            </PigPartnersButton>
          </SocialLinks>

          <MenuToggle isOpen={isMenuOpen} onClick={toggleMenu}>
            <span />
            <span />
            <span />
          </MenuToggle>
        </RightSection>
      </NavContent>
    </NavContainer>
  );
};

export default Navigation;
