import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

interface ShakeGameProps {
  onWin: () => void;
}

const shake = keyframes`
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  75% { transform: translateX(5px); }
`;

const ShakeIndicator = styled.div<{ isShaking: boolean }>`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  animation: ${props => props.isShaking ? shake : 'none'} 0.2s linear infinite;
  opacity: 0.3;
  pointer-events: none;
`;

const ShakeGame: React.FC<ShakeGameProps> = ({ onWin }) => {
  const [shakeCount, setShakeCount] = useState(0);
  const [lastShake, setLastShake] = useState(0);
  const [isShaking, setIsShaking] = useState(false);
  
  useEffect(() => {
    let shakeTimeout: NodeJS.Timeout;
    const SHAKE_THRESHOLD = 15;
    const REQUIRED_SHAKES = 20;
    const SHAKE_INTERVAL = 2000; // 2 seconds
    
    const handleShake = (e: DeviceMotionEvent) => {
      if (!e.accelerationIncludingGravity) return;
      
      const { x, y, z } = e.accelerationIncludingGravity;
      const acceleration = Math.sqrt(Math.pow(x || 0, 2) + Math.pow(y || 0, 2) + Math.pow(z || 0, 2));
      
      if (acceleration > SHAKE_THRESHOLD) {
        const now = Date.now();
        if (now - lastShake > 100) { // Debounce shakes
          setShakeCount(prev => {
            if (prev + 1 >= REQUIRED_SHAKES) {
              onWin();
              return 0;
            }
            return prev + 1;
          });
          setLastShake(now);
          setIsShaking(true);
          
          clearTimeout(shakeTimeout);
          shakeTimeout = setTimeout(() => {
            setIsShaking(false);
            setShakeCount(0);
          }, SHAKE_INTERVAL);
        }
      }
    };
    
    // Fallback for desktop
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Shift') {
        const now = Date.now();
        if (now - lastShake > 100) {
          setShakeCount(prev => {
            if (prev + 1 >= REQUIRED_SHAKES) {
              onWin();
              return 0;
            }
            return prev + 1;
          });
          setLastShake(now);
          setIsShaking(true);
          
          clearTimeout(shakeTimeout);
          shakeTimeout = setTimeout(() => {
            setIsShaking(false);
            setShakeCount(0);
          }, SHAKE_INTERVAL);
        }
      }
    };
    
    if (window.DeviceMotionEvent) {
      window.addEventListener('devicemotion', handleShake);
    }
    window.addEventListener('keydown', handleKeyPress);
    
    return () => {
      if (window.DeviceMotionEvent) {
        window.removeEventListener('devicemotion', handleShake);
      }
      window.removeEventListener('keydown', handleKeyPress);
      clearTimeout(shakeTimeout);
    };
  }, [lastShake, onWin]);
  
  return <ShakeIndicator isShaking={isShaking} />;
};

export default ShakeGame;
