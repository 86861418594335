import React from 'react';
import { createGlobalStyle } from 'styled-components';
import Navigation from './components/Navigation/Navigation';
import Whitepaper from './components/Whitepaper/Whitepaper';
import GameTester from './components/SecretPiggy/GameTester';
import SecretPiggy from './components/SecretPiggy/SecretPiggy';
import Hero from './components/Hero/Hero';
import FomoMovement from './components/FomoMovement/FomoMovement';
import Fellowship from './components/Fellowship/Fellowship';
import Footer from './components/Footer/Footer';

const GlobalStyle = createGlobalStyle`
  :root {
    /* Solana Primary Colors */
    --sol-purple: #9945FF;
    --sol-green: #14F195;
    --sol-blue: #00C2FF;
    --sol-dark: #000000;
    
    /* Extended Palette */
    --background-gradient-1: #03071E;
    --background-gradient-2: #1A1F3C;
    --text-color: #FFFFFF;
    --text-secondary: rgba(255, 255, 255, 0.7);
    --glass-bg: rgba(255, 255, 255, 0.1);
    --glass-border: rgba(255, 255, 255, 0.2);
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body {
    margin: 0;
    padding: 0;
  }

  body {
    background: linear-gradient(135deg, 
      var(--background-gradient-1),
      var(--background-gradient-2)
    );
    color: var(--text-color);
    font-family: 'Fredoka', sans-serif;
    line-height: 1.6;
    min-height: 100vh;
  }

  section {
    margin: 0;
    padding: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Press Start 2P', cursive;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .fun-font {
    font-family: 'VT323', monospace;
    font-size: 1.2em;
  }

  a {
    color: var(--sol-green);
    text-decoration: none;
    transition: color 0.3s ease;
    &:hover {
      color: var(--sol-blue);
      text-decoration: none;
    }
  }

  /* Gradient Text Utility Class */
  .gradient-text {
    background: linear-gradient(135deg, var(--sol-purple), var(--sol-blue));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

function App() {
  return (
    <div className="App">
      <GlobalStyle />
      <Navigation />
      <main style={{ position: 'relative', marginTop: '80px' }}>
        <Hero />
        <FomoMovement />
        <Fellowship />
        <Footer />
      </main>
      {/* Overlay components that don't affect main flow */}
      <div style={{ position: 'fixed', zIndex: 1000 }}>
        <Whitepaper />
        {process.env.NODE_ENV === 'development' && <GameTester />}
        <SecretPiggy />
      </div>
    </div>
  );
}

export default App;
