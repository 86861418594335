// Visitor counter API service
const API_URL = process.env.REACT_APP_API_URL || '/backend';

// In-memory fallback (only for development)
let localVisitorCount = 0;

export const getVisitorCount = async (): Promise<number> => {
  try {
    // Try to fetch from backend first
    const response = await fetch(`${API_URL}/get_count.php`);
    if (!response.ok) {
      throw new Error('Failed to fetch visitor count');
    }
    const data = await response.json();
    return data.count;
  } catch (error) {
    console.warn('Error fetching visitor count:', error);
    // Return local count if API is not yet set up
    return localVisitorCount;
  }
};

export const incrementVisitorCount = async (): Promise<void> => {
  try {
    // Try to increment on backend first
    const response = await fetch(`${API_URL}/increment_count.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    
    if (!response.ok) {
      throw new Error('Failed to increment visitor count');
    }
    
    // If successful, get the new count from the backend
    const data = await response.json();
    localVisitorCount = data.count;
  } catch (error) {
    console.warn('Error incrementing visitor count:', error);
    // Increment local count if API is not yet set up
    localVisitorCount++;
  }
};
