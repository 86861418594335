import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';

interface MoonGameProps {
  onWin: () => void;
}

const float = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
`;

const GameContainer = styled.div<{ isVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: ${props => props.isVisible ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Rocket = styled.div<{ x: number; y: number }>`
  position: absolute;
  width: 30px;
  height: 30px;
  background: #FFD700;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  transform: translate(${props => props.x}px, ${props => props.y}px) rotate(-45deg);
  transition: transform 0.1s ease;
`;

const Moon = styled.div`
  position: absolute;
  top: 50px;
  right: 50px;
  width: 40px;
  height: 40px;
  background: #FFD700;
  border-radius: 50%;
  animation: ${float} 2s infinite ease-in-out;
`;

const Obstacle = styled.div<{ x: number; y: number }>`
  position: absolute;
  width: 20px;
  height: 20px;
  background: red;
  border-radius: 50%;
  transform: translate(${props => props.x}px, ${props => props.y}px);
`;

const MoonGame: React.FC<MoonGameProps> = ({ onWin }) => {
  const [isActive, setIsActive] = useState(false);
  const [position, setPosition] = useState({ x: 100, y: window.innerHeight - 100 });
  const [obstacles, setObstacles] = useState<{ x: number; y: number }[]>([]);
  const gameLoopRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const keysPressed = useRef<Set<string>>(new Set());
  
  useEffect(() => {
    // Randomly show rocket icon
    const showInterval = setInterval(() => {
      setIsActive(Math.random() > 0.99); // 1% chance every interval
    }, 10000);
    
    return () => clearInterval(showInterval);
  }, []);
  
  useEffect(() => {
    if (!isActive) return;
    
    const handleKeyDown = (e: KeyboardEvent) => {
      keysPressed.current.add(e.key);
    };
    
    const handleKeyUp = (e: KeyboardEvent) => {
      keysPressed.current.delete(e.key);
    };
    
    const loop = setInterval(() => {
      setPosition(prev => {
        const newPos = { ...prev };
        const speed = 5;
        
        if (keysPressed.current.has('ArrowUp')) newPos.y -= speed;
        if (keysPressed.current.has('ArrowDown')) newPos.y += speed;
        if (keysPressed.current.has('ArrowLeft')) newPos.x -= speed;
        if (keysPressed.current.has('ArrowRight')) newPos.x += speed;
        
        // Check boundaries
        newPos.x = Math.max(0, Math.min(window.innerWidth - 30, newPos.x));
        newPos.y = Math.max(0, Math.min(window.innerHeight - 30, newPos.y));
        
        // Check if reached moon
        if (newPos.x > window.innerWidth - 100 && newPos.y < 100) {
          onWin();
          setIsActive(false);
        }
        
        // Check collision with obstacles
        const hasCollision = obstacles.some(obs => 
          Math.abs(obs.x - newPos.x) < 25 && Math.abs(obs.y - newPos.y) < 25
        );
        
        if (hasCollision) {
          setIsActive(false);
          return prev;
        }
        
        return newPos;
      });
    }, 16);
    
    gameLoopRef.current = loop;
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    
    // Generate obstacles
    const obstacles = Array.from({ length: 10 }, () => ({
      x: Math.random() * (window.innerWidth - 100),
      y: Math.random() * (window.innerHeight - 100)
    }));
    setObstacles(obstacles);
    
    return () => {
      if (gameLoopRef.current) {
        clearInterval(gameLoopRef.current);
      }
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [isActive, onWin]);
  
  if (!isActive) return null;
  
  return (
    <GameContainer isVisible={isActive}>
      <Rocket x={position.x} y={position.y} />
      <Moon />
      {obstacles.map((obs, i) => (
        <Obstacle key={i} x={obs.x} y={obs.y} />
      ))}
    </GameContainer>
  );
};

export default MoonGame;
