import React, { useEffect, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { getVisitorCount, incrementVisitorCount } from '../../api/visitorCounter';

const float = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
`;

const sparkle = keyframes`
  0% { opacity: 0; transform: scale(0); }
  50% { opacity: 1; transform: scale(1.2); }
  100% { opacity: 0; transform: scale(0); }
`;

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const bounce = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-30px); }
`;

const typing = keyframes`
  from { width: 0; }
  to { width: 100%; }
`;

const blink = keyframes`
  from, to { border-color: transparent }
  50% { border-color: #FFD700; }
`;

const rainbow = keyframes`
  0% { color: #FF6B6B; }
  25% { color: #4ECDC4; }
  50% { color: #45B7D1; }
  75% { color: #96E6A1; }
  100% { color: #FF6B6B; }
`;

const glitch = keyframes`
  0% { transform: translate(0); }
  20% { transform: translate(-2px, 2px); }
  40% { transform: translate(-2px, -2px); }
  60% { transform: translate(2px, 2px); }
  80% { transform: translate(2px, -2px); }
  100% { transform: translate(0); }
`;

const wiggle = keyframes`
  0%, 100% { transform: translateX(0); }
  50% { transform: translateX(-10px); }
`;

const breathe = keyframes`
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.02); }
`;

const pillsFloat = keyframes`
  0%, 100% { transform: rotate(-2deg); }
  50% { transform: rotate(2deg); }
`;

const HeroSection = styled.section`
  min-height: 100vh;
  position: relative;
  background: #0F172A;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 6rem 2rem 2rem;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  padding-top: 60px;
`;

const HeroContent = styled.div`
  text-align: center;
  color: white;
  margin-top: 2rem;
`;

const HeroBadge = styled.span`
  display: inline-block;
  padding: 0.5rem 1rem;
  background: linear-gradient(135deg, #FF6B6B 0%, #FFE66D 100%);
  border-radius: 50px;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 2rem;
  color: #0F172A;
  box-shadow: 0 4px 15px rgba(255, 107, 107, 0.4);
  transform: translateY(0);
  transition: transform 0.3s ease;
  position: relative;
  z-index: 3;

  &:hover {
    transform: translateY(-5px);
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 2.8rem;
  font-weight: bold;
  margin: 1rem auto;
  line-height: 1.2;
  width: 100%;
  padding: 0;
  display: inline-block;
  background: linear-gradient(135deg, #FF6B6B 0%, #FFE66D 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  white-space: pre-line;
  text-align: center;
  
  .highlight {
    color: #FFE66D;
    -webkit-text-fill-color: #FFE66D;
    text-shadow: 0 0 10px rgba(255, 230, 109, 0.5);
  }

  @media (max-width: 768px) {
    font-size: 1.8rem;
    margin: -1.5rem auto 0.5rem;
  }
`;

const StoryText = styled.div`
  font-size: 1.2rem;
  line-height: 1.5;
  max-width: 600px;
  margin: 0 auto;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);

  p {
    margin: 0;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 1rem;
    max-width: 90%;
  }
`;

const FloatingElements = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
`;

const Sparkles = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const Sparkle = styled.span`
  position: absolute;
  animation: ${sparkle} 2s ease-in-out infinite;
  opacity: 0.6;
  font-size: 1.5rem;

  &:nth-child(1) { top: 20%; left: 20%; animation-delay: 0s; }
  &:nth-child(2) { top: 60%; left: 80%; animation-delay: 0.3s; }
  &:nth-child(3) { top: 80%; left: 40%; animation-delay: 0.6s; }
  &:nth-child(4) { top: 30%; left: 70%; animation-delay: 0.9s; }
  &:nth-child(5) { top: 70%; left: 30%; animation-delay: 1.2s; }
  &:nth-child(6) { top: 40%; left: 60%; animation-delay: 1.5s; }
  &:nth-child(7) { top: 25%; left: 85%; animation-delay: 1.8s; }
`;

const StatsBanner = styled.div`
  display: flex;
  justify-content: center;
  gap: 4rem;
  margin: 3rem 0;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    gap: 2rem;
  }
`;

const Stat = styled.div`
  text-align: center;
  padding: 0 1.5rem;

  .number {
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--accent-primary);
    margin-bottom: 0.5rem;
    font-family: 'Press Start 2P', cursive;
    
    .loading {
      animation: ${blink} 1s infinite;
    }
  }

  .label {
    font-size: 1.1rem;
    color: var(--text-secondary);
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  @media (max-width: 768px) {
    padding: 0 1rem;
    
    .number {
      font-size: 2rem;
    }
    
    .label {
      font-size: 1rem;
    }
  }
`;

const CTAButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 3rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const Button = styled.a`
  display: inline-block;
  padding: 1rem 2rem;
  border-radius: 50px;
  font-weight: bold;
  text-decoration: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-3px);
  }
`;

const PrimaryButton = styled(Button)`
  background: linear-gradient(135deg, #FF6B6B 0%, #FFE66D 100%);
  color: #0F172A;
  box-shadow: 0 4px 15px rgba(255, 107, 107, 0.4);

  &:hover {
    box-shadow: 0 6px 20px rgba(255, 107, 107, 0.6);
  }

  animation: ${bounce} 2s infinite;
`;

const SecondaryButton = styled(Button)`
  background: transparent;
  border: 2px solid #FFE66D;
  color: #FFE66D;
  box-shadow: 0 4px 15px rgba(255, 230, 109, 0.2);

  &:hover {
    box-shadow: 0 6px 20px rgba(255, 230, 109, 0.4);
  }

  animation: ${wiggle} 2s infinite;
`;

const HeroImageContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -2rem;
  z-index: 1;

  img {
    width: 100%;
    max-width: 600px;
    height: auto;
    animation: ${breathe} 4s ease-in-out infinite;
    filter: drop-shadow(0 0 20px rgba(153, 69, 255, 0.3));
  }

  @media (max-width: 768px) {
    max-width: 90%;
    margin-top: -1rem;
    
    img {
      max-width: 100%;
    }
  }
`;

const OverlayContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 30%);
  text-align: center;
  width: 100%;
  z-index: 2;
  padding: 0;
`;

const APEButton = styled(HeroBadge)`
  transform: scale(1.2);
  cursor: pointer;
  transition: all 0.3s ease;
  animation: ${pillsFloat} 3s ease-in-out infinite;
  
  &:hover {
    transform: scale(1.3);
    box-shadow: 0 8px 25px rgba(255, 107, 107, 0.6);
  }
`;

const Hero: React.FC = () => {
  const [visitorCount, setVisitorCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initVisitorCount = async () => {
      try {
        setIsLoading(true);
        // Increment the count for this visitor
        await incrementVisitorCount();
        // Get the updated count
        const count = await getVisitorCount();
        setVisitorCount(count);
      } catch (error) {
        console.error('Error initializing visitor count:', error);
      } finally {
        setIsLoading(false);
      }
    };

    initVisitorCount();
  }, []);

  return (
    <HeroSection id="home">
      <FloatingElements>
        <Sparkles>
          <Sparkle>✨</Sparkle>
          <Sparkle>⭐</Sparkle>
          <Sparkle>💫</Sparkle>
          <Sparkle>✨</Sparkle>
          <Sparkle>⭐</Sparkle>
          <Sparkle>💫</Sparkle>
          <Sparkle>✨</Sparkle>
        </Sparkles>
      </FloatingElements>
      <Container>
        <HeroContent>
          <HeroImageContainer>
            <img src="/images/notsolguy.png" alt="NotSolGuy with Pills" />
            <OverlayContent>
              <APEButton>
                🚀 APE IN NOW
              </APEButton>
              <TitleWrapper>
                <Title>
                  The <span className="highlight">NotSolGuy</span> who FOMOed into Solana
                </Title>
              </TitleWrapper>
            </OverlayContent>
          </HeroImageContainer>
          <StoryText>
            <p>🐷 Once upon a time, there was a pig who couldn't resist the FOMO... Now he's living his best meme life on Solana! 🌟</p>
          </StoryText>
          <StatsBanner>
            <Stat>
              <div className="number">1000X+</div>
              <div className="label">Meme Potential</div>
            </Stat>
            <Stat>
              <div className="number">
                {isLoading ? (
                  <span className="loading">...</span>
                ) : (
                  visitorCount.toLocaleString()
                )}
              </div>
              <div className="label">Fellow FOMOers</div>
            </Stat>
            <Stat>
              <div className="number">$NotSol</div>
              <div className="label">Token Name</div>
            </Stat>
          </StatsBanner>
          <CTAButtons>
            <PrimaryButton href="#whitepaper">Read The Sacred Text 📜</PrimaryButton>
            <SecondaryButton 
              href="https://t.me/NotSolGuy" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              Join The FOMO Gang 🐷
            </SecondaryButton>
          </CTAButtons>
        </HeroContent>
      </Container>
    </HeroSection>
  );
};

export default Hero;
