import React from 'react';
import styled, { keyframes } from 'styled-components';

const float = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const flipCard = keyframes`
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(180deg);
  }
`;

const Section = styled.section`
  padding: 80px 0 80px;
  background: var(--background-gradient-2);
  color: var(--text-color);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(90deg, transparent, var(--sol-purple), transparent);
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Title = styled.h2`
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 20px;
  background: linear-gradient(135deg, var(--sol-purple), var(--sol-blue));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: ${float} 3s ease-in-out infinite;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Intro = styled.p`
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 60px;
  color: rgba(255, 255, 255, 0.8);
  max-width: 800px;
  margin: 0 auto 60px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  perspective: 1000px;
  height: 300px;
  cursor: pointer;
  
  &:hover .card-inner {
    transform: rotateY(180deg);
  }
`;

const CardInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  transform-style: preserve-3d;
`;

const CardSide = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 20px;
  padding: 30px;
  background: var(--glass-bg);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid var(--glass-border);
  box-shadow: 
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06),
    inset 0 0 20px rgba(255, 255, 255, 0.05);
    
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 20px;
    padding: 1px;
    background: linear-gradient(
      135deg,
      var(--sol-purple),
      var(--sol-blue)
    );
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`;

const CardFront = styled(CardSide)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const CardBack = styled(CardSide)`
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Icon = styled.div`
  font-size: 3rem;
  margin-bottom: 20px;
  animation: ${float} 3s ease-in-out infinite;
`;

const CardTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 15px;
  background: linear-gradient(135deg, var(--sol-purple), var(--sol-green));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const CardText = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 20px;
`;

const ComingSoon = styled.span`
  display: inline-block;
  padding: 8px 16px;
  background: var(--glass-bg);
  border-radius: 20px;
  font-size: 0.9rem;
  color: var(--sol-green);
`;

const Fellowship: React.FC = () => {
  const partners = [
    {
      icon: '🌞',
      title: 'Solana Swines',
      description: 'Our home chain, where transactions are faster than a pig running to lunch'
    },
    {
      icon: '💧',
      title: 'Raydium Raiders',
      description: 'Where our liquidity flows like honey'
    },
    {
      icon: '🌌',
      title: 'Jupiter Jumpers',
      description: 'Finding the best oinks across the galaxy'
    },
    {
      icon: '🎨',
      title: 'Magic Eden Miners',
      description: 'Where pixel pigs become digital gold'
    }
  ];

  return (
    <Section id="fellowship">
      <Container>
        <Title>🐷 The Fellowship of the FOMO 🐷</Title>
        <Intro>
          Join the most ambitious crossover in crypto history. More partnerships than a pig has had hot dinners!
        </Intro>
        <Grid>
          {partners.map((partner, index) => (
            <Card key={index}>
              <CardInner className="card-inner">
                <CardFront>
                  <Icon>{partner.icon}</Icon>
                  <CardTitle>{partner.title}</CardTitle>
                </CardFront>
                <CardBack>
                  <CardText>{partner.description}</CardText>
                  <ComingSoon>Coming Soon ✨</ComingSoon>
                </CardBack>
              </CardInner>
            </Card>
          ))}
        </Grid>
      </Container>
    </Section>
  );
};

export default Fellowship;
